const Config = {
  apiBase: "https://master-7rqtwti-bp3xkxvmpjwyk.eu-2.platformsh.site/api/",
  backEndUrl: "https://master-7rqtwti-bp3xkxvmpjwyk.eu-2.platformsh.site/",
  language: localStorage.getItem("language")
    ? localStorage.getItem("language") === "en"
      ? "en"
      : "no"
    : "no",
  vismaId: "20063",
  markerDestinationId: '652166c9032c6c6080b704c3',
  wooCommerceLogin: 'https://rackit.no/my-account/'
};

export default Config;
